<template>
  <div class="detail">
    <div class="container container-space">
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" style="max-height: 60vh" v-lazy="this.product.productImages[selectedImg]"
               :alt="product.productTitle"/>
          <div class="product-thumbnails">
            <ul>
              <li v-for="(image, index) in product.productImages"
                  :class="[selectedImg == index ? 'thumbnail-active': 'thumbnail-disable']" :key="index">
                <img @click="currentThumnail(index)" v-lazy="image" :alt="product.productTitle"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 text-black">
          <h1 class="my-4">{{ product.productTitle }}</h1>
          <b>Barkod:</b> <span>{{ product.productBarcode }}</span><br>
          <b>Açıklama:</b> <span>{{ product.productDetail }}</span><br>
          <b>İçindekiler:</b> <span>{{ product.productContents }}</span><br>
          <b>Aroma:</b> <span>{{ product.productAroma }}</span><br>
          <b>Raf Ömrü:</b> <span>{{ product.productLife }}</span><br>
          <div class="container table-responsive py-5">
            <table class="table table-bordered table-hover">
              <thead class="thead-dark">
              <tr class="text-center">
                <th scope="col" colspan="3">{{ product.productTableTitle }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(th,index) in JSON.parse(product.productTable)">
                <template v-if="index == 0">
                  <template v-for="(item,index) in th">
                    <th scope="row" v-if="index == 0">{{ item }}</th>
                    <th class="text-center" v-else>{{ item }}</th>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(item,index) in th">
                    <th scope="row" v-if="index == 0">{{ item }}</th>
                    <td class="text-center" v-else>{{ item }}</td>
                  </template>
                </template>

              </tr>
              <tr>
                <td colspan="3">{{ product.productTableDesc }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <img v-if='false' v-lazy="product.productTable" class="img-fluid" :alt="product.productTitle">
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'App',
  props: {
    prod: Array
  },
  data() {
    return {
      product: [],
      selectedImg: 0
    }
  },
  created() {
    this.product = this.prod[0]
  },
  methods: {
    currentThumnail: function (index) {
      this.selectedImg = index;
    }
  }
}
</script>
<style scoped>
.detail {
  height: 100vh;
  padding-top: 150px;
}

.container-space {
  margin-top: 2rem;
}

.product-thumbnails {
  display: flex;
  margin-top: 2rem;
}

.product-thumbnails > ul {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.product-thumbnails > li {
  margin-right: 10px;

}

.product-thumbnails > ul > li > img {
  width: 100px;
  cursor: pointer;
}

.thumbnail-disable {
  border: 2px solid rgba(89, 64, 64, 0.18);
}

.thumbnail-active {
  border: 2px solid #ed1c24;
}

.nav-link > img {
  width: 40px;
  border-radius: 8px;
  margin-right: 10px;
}

</style>
